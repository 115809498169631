import { setupWorker } from 'msw/browser'
import { handlers } from './handlers'

export const worker = setupWorker(...handlers)

function listenCustomEventLoadMockAPI() {
  window.addEventListener('loadMockAPI', (event: Event) => {
    const customEvent = event as CustomEvent
    if (customEvent.detail) {
      customEvent.detail.handlers.forEach(httpRoute => {
        worker.use(httpRoute)
      })
    }
  })
}

export async function enableMocking() {
  /*
  process.env.NODE_ENV !== 'development'
  utilizado como paliativo por ainda não termos solução com variaveis de ambiente
  */
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  return worker.start()
}

listenCustomEventLoadMockAPI()
